
import { Route, Routes } from 'react-router-dom';
import MainPage from './Pages/MainPage';

function App() {
  return (
<Routes>
  <Route path="/" exact Component={MainPage}></Route>
</Routes>
  )
}

export default App;
