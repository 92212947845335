import React from 'react'
import '../Css/MainPage.css'
import Navbars from '../Pages/Navbars'
import Footer from '../Pages/Footer'
import image1 from '../images/Vector (1).png'
import Cards from './Cardsdummy'
import Cardsdummy from './Cardsdummy'
import image2 from '../images/img1.png'
import image3 from '../images/img2.png'
import image4 from '../images/img3.png'
import Carddynamic from '../Pages/Carddynamic'
import baner1 from '../images/baner1.png'
import baner2 from '..//images/baner2.png'
import videofile from '../video/videonew.mp4'

const MainPage = () => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div style={{ backgroundColor: '#FFFFFF' }} className='col-lg-3 col-md-3 col-sm-6 col-6 navdiv1'>
          <img className='navimage' src={image1} />
        </div>
        <div className='col-lg-9 col-md-9 col-sm-6 col-6'><Navbars /></div>

      </div>
      <div className='row'>
        <div class="container-new">

          <video src={videofile} autoPlay loop muted />

          <div className='content'>
            <h1 className='section2h1'>LOVE HOPE <br />& PIZZA</h1>
            <button className='section2btn'>SUPPORT&ORDER NOW</button>
          </div>
        </div>
        {/* <div className='section2'>
            <h1 className='section2h1'>LOVE HOPE<br/> & PIZZA</h1>
            <button className='section2btn'>SUPPORT&ORDER NOW</button>
        </div> */}
      </div>
      <div style={{ marginTop: -10 }} className='row'>
        <div className='col-xl-12 col-lg-12 col-sm-12 section4'></div>
        <div className='col-xl-12 col-lg-12 col-sm-12 section3'>
          <h3 className='section3head'>Pizza Dudes</h3>
          <p className='section3para'>Where fLavour
            Meets Friendship</p>
          <h3 className='section3head'>Pizza Dudes</h3>
          <p className='section3para'>Where fLavour
            Meets Friendship</p>
          <h3 className='section3head'>Pizza Dudes</h3>
          <p className='section3para'>Where fLavour
            Meets Friendship</p>
        </div>
        {/* <img className='bnr1' src={baner2}></img> */}
        {/* <img className='bnr2' src={baner1}></img> */}

      </div>
      <div style={{ backgroundColor: '#eeeeee', marginBottom: -110 }} className='row cardrow'>
        <div style={{ width: '100%', textAlign: 'center', paddingTop: 30, marginBottom: 138 }} className='section5'>

          <h3 className='section5head'>MENU</h3>
          <p className='section5para'>( Item availability may vary by location )</p>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: "center" }} className='row'>
        <div className='dynamicout'>
          <Carddynamic />
        </div>
      </div>
      {/* <div style={{marginBottom:110}} className='row'>
       

        
        <div className='col-xl-12 col-lg-12 col-sm-12 section3new'>
          <h3 className='section3head'>Pizza Dudes</h3>
          <p className='section3para'>Where fLavour
Meets Friendship</p>
          <h3 className='section3head'>Pizza Dudes</h3>
          <p className='section3para'>Where fLavour
Meets Friendship</p>
<h3 className='section3head'>Pizza Dudes</h3>
<p className='section3para'>Where fLavour
Meets Friendship</p>
        </div>
        <div className='col-xl-12 col-lg-12 col-sm-12 section4new'></div>

      </div> */}


      <div className='row sectionnewparent'>
        <div className='col-xl-12 col-lg-12 col-sm-12 section4new'></div>
        <div className='col-xl-12 col-lg-12 col-sm-12 section3new'>
          <h3 className='section3head'>Pizza Dudes</h3>
          <p className='section3para'>Where fLavour
            Meets Friendship</p>
          <h3 className='section3head'>Pizza Dudes</h3>
          <p className='section3para'>Where fLavour
            Meets Friendship</p>
          <h3 className='section3head'>Pizza Dudes</h3>
          <p className='section3para'>Where fLavour
            Meets Friendship</p>
        </div>

      </div>



      <div className='section6'>
        <div className='section6parent'>

          <h2 className='section6head'>ORIGINAL FLAVORED <br /> CRUST® PIZZA</h2>
          <p className='section6para1'>Choose from 8 mouthwatering flavors. What Crust Are You?®</p>
          <p className='section6para2'>Our pizzas are made from the finest ingredients, like 100% mozzarella cheese and dough made fresh daily. Choose from 8 mouthwatering crust flavors: Butter, Butter Cheese, Asiago Cheese, Ranch, Cajun, Sesame, Garlic Herb and Italian Herb. Place your carry out or delivery order by calling your local store or ordering online today.</p>

        </div>
      </div>
      <div className='row'>
        <div className='section7image'></div>
      </div>
      <div className='section7parent'>
        <h2 className='section7head'>PLAN A PARTY WITH US!</h2>
        <p className='section7para'>Are you tired of trying to figure out how many pizzas you need to order for your next pizza party? Well, worry no more! A new pizza party calculator has just hit the market, and it takes into account the number of people and their age to recommend the perfect number of large pizzas to order for your party.</p>

        <button className='section8btn'>ORDER CATERING</button>
      </div>
      <div className='section9 '>
        <div className='section9parents'>
          <div className='section9childs'>

            {/* <div class="card cardresone" style={{width:200,height:246,textAlign:'center'}}>
  <img class="card-img-top cardrestwo" src={image2} alt="Card image cap" style={{width:50,height:50,textAlign:'center',margin:'0 auto'}}/>
  <div class="card-body carresthree" style={{width:'100%',height:166,textAlign:"center"}}>
    <h5 class="card-title">LOOKING FOR A JOB?</h5>
    <p class="card-text">Some quick example text to build</p>
    <button class=" btnnew">ORDER NOW</button>
  </div>
</div>
</div> */}

            <img class=" cardimg" src={image2} alt="Card image cap" />
            <div class="cardbdy" >
              <h5 class="cardhead">LOOKING FOR A JOB</h5>
              <p class=" cardparag">Some quick example text to build</p>
              <button class=" btncrd">APPLY HERE</button>
            </div>
          </div>


          <div className='section9childs'>

            {/* <div class="card cardresone" style={{width:200,height:246,textAlign:'center'}}>
 <img class="card-img-top cardrestwo" src={image3} alt="Card image cap" style={{width:50,height:50,textAlign:'center',margin:'0 auto'}}/>
 <div class="card-body carresthree" style={{width:'100%',height:166,textAlign:"center"}}>
   <h5 class="card-title">OWN A FRANCHISE</h5>
   <p class="card-text">Some quick example text to build</p>
   <button class=" btnnew">ORDER NOW</button>
 </div>
</div>
</div> */}

            <img class=" cardimg" src={image2} alt="Card image cap" />
            <div class=" cardbdy">
              <h5 class=" cardhead">OWN A FRANCHISE</h5>
              <p class=" cardparag">Some quick example text to build</p>
              <button class=" btncrd">LEARN MORE</button>
            </div>
          </div>

          <div className='section9childs'>

            {/* <div class="card cardresone" style={{width:200,height:246,textAlign:'center'}}>
 <img class="card-img-top cardrestwo" src={image4} alt="Card image cap" style={{width:50,height:50,textAlign:'center',margin:'0 auto'}}/>
 <div class="card-body carresthree" style={{width:'100%',height:166,textAlign:"center"}}>
   <h5 class="card-title">START A DOUGH - RAISING</h5>
   <p class="card-text">Some quick example text to build</p>
   <button class=" btnnew">ORDER NOW</button>
 </div>
</div>
</div> */}

            <img class=" cardimg" src={image2} alt="Card image cap" />
            <div class=" cardbdy">
              <h5 class=" cardhead">START A DOUGH-RAISING</h5>
              <p class=" cardparag">Some quick example text to build</p>
              <button class=" btncrd">REQUEST A KIT</button>
            </div>
          </div>

        </div>
      </div>
      {/* 
    <div className='row'> */}
      <Footer />
      {/* </div> */}
    </div>
  )
}

export default MainPage
