import React from 'react'
import faceb from '../images/FB.png'
import xx from '../images/XX.png'
import insta from '../images/INSTA.png'
import '../Css/Footer.css'
const Footer = () => {
  return (
    
    <div style={{background:'black'}} class="footer">
    <div class="container">     
        <div class="row footflx">                       
            <div class="col-xm-3 col-lg-3 col-sm-3 col-xs-12 flxchild">
                <div class="single_footer footchild">
                    <h4 className='foothead' style={{color:'#FECD2F'}}>OUR MENU</h4>
                    <ul className='listfoot'>
                        <li><a href="#">order online</a></li>
                        <li><a href="#">Flavoured crust</a></li>
                        <li><a href="#">our pizza</a></li>
                        <li><a href="#">oven baked subs</a></li>
                        <li><a href="#">fresh salads</a></li>
                        <li><a href="#">howie wings</a></li>
                        <li><a href="#">no dough bowl</a></li>
                        <li><a href="#">gluten free</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-xm-3 col-md-3 col-sm-3 col-xs-12 col-lg-3 flxchild">
                <div class="single_footer single_footer_address footchild">
                    <h4  className='foothead 'style={{color:'#FECD2F'}}>FLAVOURED CRUST</h4>
                    <ul className='listfoot'>
                        <li><a href="#">Butter</a></li>
                        <li><a href="#">Butter cheese</a></li>
                        <li><a href="#">Asiago cheese</a></li>
                        <li><a href="#">Ranch</a></li>
                        <li><a href="#">Cajun</a></li>
                        <li><a href="#">Sesame</a></li>
                        <li><a href="#">Garlic Herb</a></li>
                        <li><a href="#">Italian Herb</a></li>
                        
                    </ul>
                </div>
            </div>
            <div class="col-xm-3 col-md-3 col-sm-3 col-xs-12 col-lg-3 flxchild footchild">
                <div class="single_footer single_footer_address footchild">
                    <h4  className='foothead ' style={{color:'#FECD2F'}}>OUR BRAND</h4>
                    <ul className='listfoot'>
                        <li><a href="#">About us</a></li>
                        <li><a href="#">Gift Cards</a></li>
                        <li><a href="#">Jobs</a></li>
                        <li><a href="#">Community</a></li>
                        <li><a href="#">Involvement</a></li>
                        <li><a href="#">Fundraising</a></li>
                        <li><a href="#">Own a Franchise</a></li>
                    </ul>
                </div>
            </div>
          

            <div class="col-xm-3 col-md-3 col-sm-3 col-xs-12 col-lg-3 flxchild">
                <div class="single_footer single_footer_address footchild">
                    <h4  className='foothead ' style={{color:'#FECD2F'}}>CORPORATE</h4>
                    <ul className='listfoot'>
                        <li><a href="#">Contact us</a></li>
                        <li><a href="#">Press Room</a></li>
                        <li><a href="#">Legal </a></li>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Terms and conditions</a></li>
                    </ul>
                </div>
            </div>
          
                <div style={{paddingTop:40}} class="flxchild footchild">
                    <ul className='footli'>
                        <li className='foothead' style={{color:'#FECD2F'}}>FOLLOW US</li>
                        <li><img src={faceb}></img></li>
                        <li><img src={xx}></img></li>
                        <li><img src={insta}></img></li>
                    </ul>
                </div>                          
            </div>      
        </div> 
        {/* <div class="row">
            <div class="col-lg-12 col-sm-12 col-xs-12">
                <p class="copyright">Copyright © 2019 <a href="#">Akdesign</a>.</p>
            </div>                 
        </div>                  */}
    </div>

  )
}

export default Footer
