import React from 'react'
import imgnav from '../images/navbarline.png'

const Navbars = () => {
  return (
    <nav style={{backgroundColor:'#FFFFFF',paddingTop:20}} class="navbar navbar-expand-lg  ">
    <div class="container-fluid">
   
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    
      <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <form style={{borderBlockStyle:'none'}} class="d-flex" role="search">
          <input style={{backgroundColor:'#EEEEEE',borderRadius:8}} class="form-control me-2" type="search" placeholder="Find a store near you" aria-label="Search"/>
          <button style={{backgroundColor:'#0099B4',color:'#FFFFFF',width:176,height:41}} class="btn btn-outline-success" type="submit">ORDER NOW</button>
        </form>
        {/* <a class="navbar-brand" href="#">Hidden brand</a> */}
        <ul style={{marginLeft:38,fontFamily:'Rubik',fontSize:16,fontWeight:600}} class="navbar-nav me-auto mb-2 mb-lg-0 listone">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">MENU</a>
          </li>
          <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">DEALS</a>
            {/* <a class="nav-link" href="#">Link</a> */}
          </li>
          <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">JOBS</a>
            {/* <a class="nav-link disabled" aria-disabled="true">Disabled</a> */}
          </li>
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">CONTACT US</a>
          </li>
          <li class="nav-item">
            {/* <a class="nav-link active" aria-current="page" href="#">CONTACT US</a> */}
            <img style={{margin:0,marginTop:-4}} src={imgnav}/>
          </li>
        </ul>
      
      </div>
    </div>
  </nav>
  )
}

export default Navbars
