import React from 'react'
import '../Css/Carddynamic.css'
import image2 from '../images/pizza1.png'

import image4 from '../images/pizza3.png'
import image5 from '../images/pizza5.png'
import image6 from '../images/pizza6.png'
import image7 from '../images/pizza7.png'
import image8 from '../images/pizza8.png'
import image9 from '../images/pizza9.png'
import image10 from '../images/pizza10.png'
import image11 from '../images/pizza3/image 5.png'

const Carddynamic = () => {
  return (
    <div className='parentdynamic'>
    <div className='childdynamic'>
       <div className='imgclddiv'> <img style={{width:200,height:150}} src={image2} className='imgdynamic'></img></div>
        <h5 className='headdynamic'>PIZZAS</h5>
        <p className='paradynamic'>$99* Above for one Person</p>
        <button className='btndynamic'>ORDER NOW</button>
    </div>
    <div className='childdynamic'>
    <div className='imgclddiv'><img src={image4} className='imgdynamic'></img></div>
        <h5 className='headdynamic'>FLATBREAD</h5>
        <p className='paradynamic'>$99* Above for one Person</p>
        <button className='btndynamic'>ORDER NOW</button>
    </div>
    <div className='childdynamic'>
    <div className='imgclddiv'><img src={image11} className='imgdynamic'></img></div>
        <h5 className='headdynamic'>OVER-BAKED SUBS</h5>
        <p className='paradynamic'>$99* Above for one Person</p>
        <button className='btndynamic'>ORDER NOW</button>
    </div>
    <div className='childdynamic'>
<div className='imgclddiv'><img src={image5} className='imgdynamic'></img></div>
        <h5 className='headdynamic'>HOWIE BREAD</h5>
        <p className='paradynamic'>$99* Above for one Person</p>
        <button className='btndynamic'>ORDER NOW</button>
    </div>
    <div className='childdynamic'>
    <div className='imgclddiv'><img src={image6} className='imgdynamic'></img></div>
        <h5 className='headdynamic'>FRESH SALADS</h5>
        <p className='paradynamic'>$99* Above for one Person</p>
        <button className='btndynamic'>ORDER NOW</button>
    </div>
    <div className='childdynamic'>
    <div className='imgclddiv'><img src={image7} className='imgdynamic'></img></div>
        <h5 className='headdynamic'>NO-DOUGH BOWL</h5>
        <p className='paradynamic'>$99* Above for one Person</p>
        <button className='btndynamic'>ORDER NOW</button>
    </div>
    <div className='childdynamic'>
    <div className='imgclddiv'><img  src={image8} className='imgdynamic'></img></div>
        <h5 className='headdynamic'>DESSERTS</h5>
        <p className='paradynamic'>$99* Above for one Person</p>
        <button className='btndynamic'>ORDER NOW</button>
    </div>
    <div className='childdynamic'>
    <div className='imgclddiv'><img style={{width:200,height:150}} src={image9} className='imgdynamic'></img></div>
        <h5 className='headdynamic'>DRINKS</h5>
        <p className='paradynamic'>$99* Above for one Person</p>
        <button className='btndynamic'>ORDER NOW</button>
    </div>
    <div className='childdynamic'>
    <div className='imgclddiv'><img style={{width:200,height:150}} src={image10} className='imgdynamic'></img></div>
        <h5 className='headdynamic'>SECRET MENU</h5>
        <p className='paradynamic'>$99* Above for one Person</p>
        <button className='btndynamic'>ORDER NOW</button>
    </div>
      
    </div>
  )
}

export default Carddynamic
